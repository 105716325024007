//< !--Page loading Browser Check-- >
//Get user location
//$.getJSON("https://json.geoiplookup.io/api", function (data) {
//    var country_code = data.country_code;
//    var ccode = ["BE", "BG", "CZ", "DK", "DE", "EE", "IE", "EL", "ES", "FR", "HR", "IT", "CY", "LV", "LT", "LU", "HU", "MT", "NL", "AT", "PL", "PT", "RO", "SI", "SK", "FI", "SE", "UK", "GB", "GB-SCT", "GB-WLS", "GB-NIR"];
//    if (ccode.indexOf(country_code) > -1) {
//        $('#eugdpr').css("display", "block");
//    }
//});


//own js for browser compatibility test and EU users blocking site access
if (window.location.href.indexOf("https://www.jodi365.com") > -1 || window.location.href.indexOf("https://jodi365.com") > -1) {

    //<!--clicky-->
    var clicky_site_ids = clicky_site_ids || [];
    clicky_site_ids.push(66351067);
    (function () {
        var s = document.createElement('script');
        s.type = 'text/javascript';
        s.async = true;
        s.src = '//static.getclicky.com/js';
        (document.getElementsByTagName('head')[0] || document.getElementsByTagName('body')[0]).appendChild(s);
    })();

}


   //< !--Amplitude tracking code-- >
//  (function (e, t) {
//         var n = e.amplitude || {_q: [], _iq: {} }; var r = t.createElement("script")
//             ; r.type = "text/javascript"; r.async = true
//             ; r.src = "https://cdn.amplitude.com/libs/amplitude-4.5.2-min.gz.js"
//                     ; r.onload = function () {
//                         if (e.amplitude.runQueuedFunctions) {
//             e.amplitude.runQueuedFunctions()
//         } else {
//             console.log("[Amplitude] Error: could not load SDK")
//         }
//         }
//         ; var i = t.getElementsByTagName("script")[0]; i.parentNode.insertBefore(r, i)
//                     ; function s(e, t) {
//             e.prototype[t] = function () {
//                 this._q.push([t].concat(Array.prototype.slice.call(arguments, 0))); return this
//             }
//         }
//         var o = function () {this._q = []; return this }
//             ; var a = ["add", "append", "clearAll", "prepend", "set", "setOnce", "unset"]
//                     ; for (var u = 0; u < a.length; u++) {s(o, a[u])} n.Identify = o; var c = function () {
//                         this._q = [];
//                         return this
//         };
//         var l = ["setProductId", "setQuantity", "setPrice", "setRevenueType", "setEventProperties"]
//                     ; for (var p = 0; p < l.length; p++) {s(c, l[p])} n.Revenue = c
//         ; var d = ["init", "logEvent", "logRevenue", "setUserId", "setUserProperties", "setOptOut", "setVersionName", "setDomain", "setDeviceId", "setGlobalUserProperties", "identify", "clearUserProperties", "setGroup", "logRevenueV2", "regenerateDeviceId", "logEventWithTimestamp", "logEventWithGroups", "setSessionId", "resetSessionId"]
//                     ; function v(e) {
//             function t(t) {
//                 e[t] = function () {
//                     e._q.push([t].concat(Array.prototype.slice.call(arguments, 0)))
//                 }
//             }
//                         for (var n = 0; n < d.length; n++) {t(d[n])}
//         } v(n); n.getInstance = function (e) {
//             e = (!e || e.length === 0 ? "$default_instance" : e).toLowerCase()
//                             ; if (!n._iq.hasOwnProperty(e)) {n._iq[e] = { _q: [] }; v(n._iq[e]) } return n._iq[e]
// }; e.amplitude = n
// })(window, document);
// var AMPapikey;
// if (window.location.href.indexOf("testing.jodi365") > 0)
//     AMPapikey = "fd6dafd8befce054b2b28cc6833c8d0d";
// else
//     AMPapikey = "1cf7259920c092d9608c0b04d6edc71b";
// window.amplitude.getInstance().init(AMPapikey);
//CleverTap
var clevertap = {event: [], profile: [], account: [], onUserLogin: [], notifications: [] };
// replace with the CLEVERTAP_ACCOUNT_ID with the actual ACCOUNT ID value from your Dashboard -> Settings page
// var CLEVERTAPACCOUNTID;
// if (window.location.href.indexOf("testing.jodi365") > 0 || window.location.href.indexOf("staging.jodi365") > 0 || window.location.href.indexOf("localhost") > 0)
//     CLEVERTAPACCOUNTID = "TEST-9WR-R8K-884Z";//for testing,staging,localhost.
// else
//     CLEVERTAPACCOUNTID = "8WR-R8K-884Z";//change for live
// clevertap.account.push({"id": CLEVERTAPACCOUNTID });
// (function () {
//     var wzrk = document.createElement('script');
//     wzrk.type = 'text/javascript';
//     wzrk.async = true;
//     wzrk.src = ('https:' == document.location.protocol ? 'https://d2r1yp2w7bby2u.cloudfront.net' : 'http://static.clevertap.com') + '/js/a.js';
//     var s = document.getElementsByTagName('script')[0];
//     s.parentNode.insertBefore(wzrk, s);
// })();

  //< !--Screen recording Live session-- >
if (window.location.href.indexOf("https://www.jodi365.com") > -1 || window.location.href.indexOf("https://jodi365.com") > -1) {

    !function (w, d, t, u) {
        if (w.__ls) return; var f = w.__ls = function () { f.push ? f.push.apply(f, arguments) : f.store.push(arguments) };
        if (!w.__ls) w.__ls = f; f.store = []; f.v = "1.0";

        var ls = d.createElement(t); ls.async = true; ls.src = u;
        var s = d.getElementsByTagName(t)[0]; s.parentNode.insertBefore(ls, s);
    }(window, document, 'script', ('https:' == window.location.protocol ? 'https://' : 'http://') + 'cdn.livesession.io/track.js');

    __ls("setOptions", { accountID: "1da7c504", websiteID: "ec3407f2" });
    __ls("newPageView")
}

// facebook pixel 
if (window.location.href.indexOf("https://www.jodi365.com") > -1 || window.location.href.indexOf("https://jodi365.com") > -1){
! function (f, b, e, v, n, t, s) {
    if (f.fbq) return;
    n = f.fbq = function () {
      n.callMethod ?
        n.callMethod.apply(n, arguments) : n.queue.push(arguments)
    };
    if (!f._fbq) f._fbq = n;
    n.push = n;
    n.loaded = !0;
    n.version = '2.0';
    n.queue = [];
    t = b.createElement(e);
    t.async = !0;
    t.src = v;
    s = b.getElementsByTagName(e)[0];
    s.parentNode.insertBefore(t, s)
  }(window,
    document, 'script', 'https://connect.facebook.net/en_US/fbevents.js');
  fbq('init', '266504682395925');
  fbq('track', 'PageView');
  }